<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card">
        <Toolbar class="p-mb-4">
          <template #start>
            <div style="font-size: 1.4em; color: inherit" class="p-mr-3">{{ $t('Payments') }}</div>
<!--              <AddNewButton :label="$t('Add')" @click="addNewItem" class="p-mr-5"/>-->
<!--            <div v-if="totalPaymentsSum" class="total-sum-block">-->
<!--              <div>{{ $t('Total sum') }}: {{ formatCurrency(totalPaymentsSum) }}</div>-->
<!--              <div v-if="debit" style="font-size: 0.6rem" class="p-mt-1">-->
<!--                <span class="total-sum-block">Credit: {{ formatCurrency(credit) }}, </span>-->
<!--                <span class="total-sum-block">Debit: {{ formatCurrency(debit) }}</span>-->
<!--              </div>-->
<!--            </div>-->

      <!--        <DatesSelector :defaultDateIndex="5" class="p-mr-2" v-show="false"/>-->
          </template>
          <template #end>
            <DatesRangeCalendar :disabled="dataTableIsLoading" @change-dates-range="changeDatesRange" v-show="true"/>
          </template>
        </Toolbar>
        <div class="p-d-flex p-ai-center p-flex-wrap p-mb-4 p-mt-0">
          <div class="result-block p-m-1">
            {{ $t('Total sum') }}: <b>{{ formatCurrency(totalPaymentsSum) }}</b>
          </div>
          <template v-if="debit">
            <div class="result-block p-m-1">
              {{ $t('Credit') }}: <span class="green-color">{{ formatCurrency(credit) }}</span>
            </div>
            <div class="result-block p-m-1">
              {{ $t('Debit') }}: <span class="warning-color">{{ formatCurrency(debit) }}</span>
            </div>
          </template>
        </div>
        <Spinner v-if="isLoading"></Spinner>
      <!--  <DesktopDataTable v-if="!isLoading && !$store.state.tabletLayout"-->
        <DesktopDataTable v-else
                          :dataTableIsLoading="dataTableIsLoading"
                          :tableName="$t('Payments')"
                          :itemsArrayIsEmpty="itemsArrayIsEmpty"
                          :sort="sort"
                          :showSearchInput="false"
                          :showButtons="actionButtonsCount > 0"
                          :actionButtonsCount="actionButtonsCount"
                          @sort-items="sortItems"
                          :headers="[
                    {name: 'date', title: $t('Date'), width: '', sortable: true},
                    // {name: 'order', title: 'Order', width: '', sortable: false},
                    {name: 'car', title: $t('Car'), width: '', sortable: false},
                    {name: 'name', title: $t('Description'), width: '', sortable: true},
                    {name: 'qty', title: $t('Qty'), width: '', sortable: true},
                    {name: 'total_time', title: $t('Time'), width: '', sortable: true},
                    {name: 'percent', title: $t('Percent'), width: '', sortable: true},
                    {name: 'sum', title: $t('Sum'), width: '', sortable: true},
                ]">
          <template v-slot:body>
            <tr v-for="(item, index) of items" :key="index"
                :class="{'updated-item': isItemUpdated(item.id)}">
              <td class="table-data">{{ formatDate(item.date) }}</td>
<!--              <td class="table-data">-->
<!--                <span v-if="item.order?.number"><router-link :to="{ path: `/orders/${item.order.number}` }" target="_blank">{{ item.order.number }}</router-link></span>-->
<!--                <i v-else class="ti-minus"></i>-->
<!--              </td>-->
              <td class="table-data">
                <div v-if="item.car" class="p-d-flex">
<!--                    <router-link :to="{ path: `/cars/${item.car.id}` }" target="_blank" class="p-d-flex p-flex-wrap">-->
                      <i class="ti-car p-mr-1"></i>
                      <div class="p-mr-1" v-if="item.car.make">{{ item.car.make.name }}</div>
                      <div class="p-mr-1" v-if="item.car.model">{{ item.car.model.name }}</div>
<!--                    </router-link>-->
                </div>
                <i v-else class="ti-minus"></i>
              </td>
              <td class="table-data">
                <span v-if="item.description">{{ item.description }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td class="table-data">{{ formatDecimal(item.qty) }}</td>
              <td class="table-data">
                <span v-if="item.total_time">{{ formatDecimal(item.total_time) }} {{ $t('h') }}.</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td class="table-data">
                <span v-if="item.percent">{{ formatDecimal(item.percent) }}%</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td class="table-data">
                <span :class="item.sum >= 0 ? 'green-color' : 'warning-color'"><span v-if="item.sum >= 0">+</span>{{ formatCurrency(item.sum) }}</span></td>
              <td v-if="actionButtonsCount > 0">
                <div class="p-d-flex">
                  <EditButton v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin || $store.state.user.role === constants.userRoles.accountant || $store.state.user.role === constants.userRoles.moderator"
                              :disabled="$store.state.user.role !== constants.userRoles.superAdmin && $store.state.user.role !== constants.userRoles.admin && $store.state.user.role !== constants.userRoles.accountant && $store.state.user.role !== constants.userRoles.moderator"
                              @click="editItem(item)" />
                  <DeleteButton @click="confirmItemDelete(item)"
                                v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin || $store.state.user.role === constants.userRoles.accountant || $store.state.user.role === constants.userRoles.moderator"/>
                </div>
              </td>
            </tr>
          </template>
        </DesktopDataTable>

        <OwnPaginator v-show="!itemsArrayIsEmpty"
                      :rows="pagination.perPage"
                      :currentPage="pagination.currentPage - 1"
                      :totalPages="pagination.pageCount"
                      :totalRecords="pagination.totalCount"
                      :rowsPerPageOptions="[5,10,25,50,100,200]"
                      :pageLinkSize="11">
        </OwnPaginator>

        <TotalRecordsData :pagination="pagination" :itemsArrayIsEmpty="itemsArrayIsEmpty"/>
      </div>
    </div>
  </div>
</template>
<script>
import httpClient from '@/services/http.services'
// import httpMixins from "@/mixins/httpMixins";
import datatableMixins from "@/mixins/datatableMixins";
import formatMixins from "@/mixins/formatMixins";
// import EmployeePaymentModal from "@/pages/users/components/EmployeePaymentModal";
// import ConfirmDeleteModal from "@/components/ConfirmDeleteModal";
import DatesRangeCalendar from "@/components/DatesRangeCalendar";
import settings from "@/settings";
// import DatesSelector from "@/components/DatesSelector";
import showErrorsMixins from "@/mixins/showErrorsMixins";

export default {
  mixins: [ showErrorsMixins, datatableMixins, formatMixins ],
  components: { DatesRangeCalendar },
  emits: ['update-items', 'updateItems'],
  props: {
    user: Object,
  },
	data() {
		return {
      // dateRange: '',
      totalPaymentsSum: 0,
      debit: 0,
      credit: 0,
      fromTimestamp: null,
      toTimestamp: null,
      fieldsExpand: 'car',
      apiLink: 'employee-payment/get-master-payments',
      sort: {
        column: 'date',
        direction: '-',
      },
      routePath: '/employee-payments',
      // sort: {
      //   column: 'id',
      //   direction: '-',
      // },
		}
	},
  // watch: {
  //   '$route'() {
  //     const route = this.$route.path.split('/')
  //     if (this.$route.path === '/login' || route.indexOf('employee') === -1 || route.indexOf('payments') === -1) {
  //       return false
  //     }
  //     // this.checkRouteQuery()
  //     this.loadData()
  //   }
  // },
  mounted() {
    // this.loadData()
    if (settings.autoLogoutTimeout && this.$store.state.logoutTimestampIsOverdue) return false
    this.checkRouteQuery()
    this.generateThisMonthRange()
    this.getItems()
    // this.getItems()
  },
  methods: {
    changeDatesRange({ fromTimestamp, toTimestamp }, loadData = false) {
      this.fromTimestamp = fromTimestamp
      this.toTimestamp = toTimestamp
      if (loadData) {
        // console.log('load data')
        this.getItems()
      }
    },
    // loadData() {
    //   if (!this.user) return false
    //   if (this.$route.params.id) {
    //     this.checkRouteQuery()
    //     this.getItems()
    //   }
    // },
    async getItems(showSpinner = false) {
      this.dataTableIsLoading = true
      // console.log(this.$store.state.user)
      // if (!this.$store.state.user.user_id) {
      //   console.log('no')
      // } else {
      //   console.log('yes')
      // }
      // console.log('get items')
      if (showSpinner) {
        this.isLoading = true
      }

      try {
        // const { status, data, headers } = await httpClient.post(`${this.apiLink}?page=${this.pagination.currentPage}&per-page=${this.pagination.perPage}&sort=${this.sort.direction + this.sort.column}&search=${this.searchData}&expand=${this.fieldsExpand}`, { id: this.$route.params.id })
        const { status, data, headers } = await httpClient({
          url: this.apiLink,
          method: 'post',
          params: {
            page: this.pagination.currentPage,
            'per-page': this.pagination.perPage,
            sort: this.sort.direction + this.sort.column,
            search: this.searchData,
            expand: this.fieldsExpand,
          },
          data: {
            userId: this.$route.params.id,
            dateFrom: this.fromTimestamp,
            dateTo: this.toTimestamp,
          }
        })

        if (status === 200 && data) {
          this.pagination.totalCount = +headers['x-pagination-total-count']
          this.pagination.pageCount = +headers['x-pagination-page-count']
          // this.pagination.currentPage = +headers['x-pagination-current-page']
          // this.pagination.perPage = +headers['x-pagination-per-page']
          // if (data.payments_list) {
          this.items = data.payments_list ? data.payments_list : []
          // }
          this.totalPaymentsSum = data.total_sum ? data.total_sum : 0
          this.debit = data.debit ? data.debit : 0
          this.credit = data.credit ? data.credit : 0


          if (!this.items?.length) {
            this.pagination.pageCount = 1
            // this.pagination.currentPage = 1
          }
        }
      } catch(err) {
        this.totalPaymentsSum = 0
        this.debit = 0
        this.credit = 0
        this.showError(err)
      } finally {
        this.isLoading = false
        this.dataTableIsLoading = false
      }
    },
	},
  computed: {
    itemToDeleteData() {
      return 'delete data'
    },
    actionButtonsCount() {
      // if (this.$store.state.user.role === this.constants.userRoles.superAdmin || this.$store.state.user.role === this.constants.userRoles.admin || this.$store.state.user.role === this.constants.userRoles.accountant  || this.$store.state.user.role === this.constants.userRoles.moderator) {
      //   return 2
      // } else {
      //   return 0
      // }
      return 0
    },
  }
}
</script>
<style scoped lang="scss">
.total-sum-block {
  font-size: 1.4em;
  //font-weight: 400;
}

.table-data {
  height: 45px;
}

.result-block {
  padding-right: 16px;
}
</style>